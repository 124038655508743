/* eslint-disable jsx-a11y/media-has-caption */
import ShineBorder from "~/components/shine-border";

import { Card } from "@nextui-org/card";
import { Modal, ModalContent, useDisclosure } from "@nextui-org/modal";
import posthog from "posthog-js";
import { LuPlayCircle } from "react-icons/lu";
import { PUBLIC_TRIBE_CLOUDFRONT_URL } from "~/utils/constants";
import ReactPlayer from "react-player";

export default function HowItWorks() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <Card
        className="-translate-y-1/2 rounded-full shadow-lg md:shadow-xl"
        isPressable
        onPress={() => {
          posthog.capture("how_it_works_opened");
          onOpen();
        }}
      >
        <ShineBorder
          borderRadius={48}
          borderWidth={4}
          className="relative flex h-20 w-28 flex-col items-center justify-center overflow-hidden rounded-full !bg-background"
          color={["#A07CFE", "#FE8FB5", "#FFBE7B"]}
        >
          <div className="flex justify-center items-center px-8 py-6 rounded-full cursor-pointer">
            <LuPlayCircle className="w-8 h-8 mr-4 text-foreground" />
            <span className="text-center text-xl sm:text-2xl font-medium text-foreground">
              How it works?
            </span>
          </div>
        </ShineBorder>
      </Card>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="center"
        size="xs"
        backdrop="blur"
      >
        <ModalContent>
          <ReactPlayer
            url="https://www.youtube.com/shorts/B7WJKdf2VIU"
            playsinline
            loop
            playing
            muted={false}
            controls
            className="w-auto h-full aspect-[9/16]"
            width="100%"
            height="100%"
          />
        </ModalContent>
      </Modal>
    </>
  );
}
