import type { MetaFunction } from "@remix-run/node";
import { Divider } from "@nextui-org/react";
import { HiSparkles } from "react-icons/hi2";

import Search from "~/components/search";
import Suggestions from "~/components/suggestions";
import HowItWorks from "~/components/how-it-works";
import Navbar from "~/components/navbar";
import { PrefetchPageLinks } from "@remix-run/react";
import { OG_METADATA } from "~/utils/constants";
import Spotlight from "~/components/spotlight";

export const meta: MetaFunction = () => {
  return [
    { charset: "utf-8" },
    { title: "Tribe - Find service experts who get your vibe ✨" },
    {
      name: "description",
      content:
        "Discover India's top service experts on Tribe! From photographers to interior designers and fitness trainers, find the perfect match for your needs today.",
    },
    ...OG_METADATA,
  ];
};

export default function Index() {
  return (
    <>
      <Navbar hideLogo={true} />
      <section className="flex flex-col items-center relative">
        <div className="max-w-xl w-full px-4 text-center z-10  mt-12 md:mt-0 mb-4">
          <h1 className="text-5xl md:text-8xl font-black text-primary mb-2  animate-fade-down animate-duration-800">
            tribe<span className="text-xl md:text-4xl">.best</span>
          </h1>
          <p className="text-lg md:text-xl font-semibold drop-shadow-md leading-9">
            Find service experts who get your vibe
            <HiSparkles className="inline-block ml-1 mb-1 text-primary" />
          </p>
        </div>
      </section>

      <div className="w-full pt-2 sm:pt-6 sticky top-0 z-20 px-4 sm:px-6 pb-12 flex justify-center bg-gradient-to-b from-background via-background to-transparent">
        <div className="max-w-xl w-full">
          <Search placeholder="Describe any service you need..." />
        </div>
      </div>

      <div>
        <Suggestions />
      </div>
      <div className="flex flex-col items-center pt-24">
        <div className="w-full flex flex-col items-center">
          <Divider />
          <HowItWorks />
        </div>
      </div>
      <div className="flex flex-col md:items-center px-4 sm:px-6 w-full mb-8">
        <Spotlight />
      </div>

      <PrefetchPageLinks page="/search" />
    </>
  );
}
